/*NOTE : The following css restricts user actions on calendar other than scheduled Events*/
/* .css-3f54eu .rs__cell:not(.rs__event__item) {
    pointer-events: none !important;
}

.css-3f54eu .rs__cell .rs__event__item {
    pointer-events: auto !important;
}

.rs__cell:not(.rs__multi_day) {
    pointer-events: none !important;
}

.rs__cell .rs__multi_day {
    pointer-events: auto !important;
}

.rs__cell.rs__today_cell {
    pointer-events: auto !important;
} */

/*NOTE : Hides icons on popup card in calendar*/
.rs__popper_actions {
    display: none !important;
}

/*NOTE : To align slot cards in calendar*/
.dPCalendarWrapper> * {
    box-sizing: initial !important;
}
.dPCalendarWrapper .rs__cell .rs__event__item{
    display: inline-block;
}