body {
  margin: 0;
  font-family: 'poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track { 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6defa; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eee8f5; 
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.coming-soon-container {
  position: fixed;
  left: 46%;
  font-size: xx-large;
  top: 20%;
  color: #6360CD;
  font-weight: 700;
}

html, body {
  position:relative;
  margin:0;
  padding:0;
  overflow:auto;
}